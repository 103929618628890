import Products from './Products/Products';

const MenShop = () => {
  return (
    <div>
      {/* <h5>Men shopping</h5>
      <section>Shop by brands</section> */}
      <Products />
    </div>
  );
};

export default MenShop;
