import Products from './Products/Products';

const KidsShop = () => {
  return (
    <div>
      <h5>kids shopping</h5>
      <Products />
    </div>
  );
};

export default KidsShop;
