export const CLIENT_CUSTOM_ERROR_TYPE = {
  TOKEN_MISSING: 'CE_TOKEN_MISSING',
  TOKEN_INVALID: 'CE_TOKEN_INVALID',
};

export const SERVER_ERROR = {
  JWT_EXPIRED: 'jwt expired',
  JWT_MALFORMED: 'jwt malformed',
};

export const TOKEN_TYPE = {
  NONE: 'NO_AUTH',
  MOBILE_VERIFICATION: 'MOBILE_VERIFICATION_TOKEN',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION_TOKEN',
  AUTH: 'AUTH_TOKEN',
};

export const AXIOS_REQUEST_TIMEOUT = 5000;
export const NUMBER_OF_OTP_DIGITS = 4;
export const DEFAULT_RESEND_OTP_TIMER_DURATION = 10;
export const DEFAULT_TOAST_AUTO_CLOSE_TIME = 5000;
