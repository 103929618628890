const errorMessages = {
  NETWORK_ERROR_TRY_AGAIN: 'Network error, try again!',
  UNEXPECTED_ERROR: 'Unexpected error',
  OTP_REQUIRED: 'Please enter OTP',
  TOKEN_MISSING: 'Token is required, login again!',
  TOKEN_INVALID: 'Invalid or Expired token, login again!',
  SOMETHING_WRONG: 'Something went wrong! Try later.',
};

export default errorMessages;
