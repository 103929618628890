import { faHeart, faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import SpriteIcon from '../UI/SpriteIcon/SpriteIcon';
import classes from './Navbar.module.css';
import ProfileUserActions from './ProfileUserActions';

const NavbarActions = () => {
  return (
    <div className={classes['navbar-actions-container']}>
      <div className={classes['navbar-action-profile-box']}>
        <div className={`${classes['navbar-action']}`}>
          <FontAwesomeIcon icon={faUser} />
          <span className={classes['navbar-action-label']}>Profile</span>
        </div>
        <ProfileUserActions />
      </div>

      <NavLink to="/wishlist" className={classes['navbar-action']}>
        <FontAwesomeIcon icon={faHeart} />
        <span className={classes['navbar-action-label']}>Wishlist</span>
      </NavLink>

      <NavLink
        to="checkout/bag"
        className={`${classes['navbar-action']} ${classes.cart}`}>
        {/* Included just to get an idea about how image sprites work */}
        <SpriteIcon className={classes['sprites-headerBag']} />
        <span className={classes['navbar-action-label']}>Bag</span>
        <span className={classes['cart-items-badge']}>3</span>
      </NavLink>
    </div>
  );
};

export default NavbarActions;
