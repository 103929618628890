import Products from './Products/Products';

const WomenShop = () => {
  return (
    <div>
      <h5>Women shopping</h5>
      <Products />
    </div>
  );
};

export default WomenShop;
