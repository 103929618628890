import classes from './Footer.module.css';

const FooterMen = () => {
  return (
    <>
      <div className={classes['footer-section']}>
        <h5> MEN&apos;S SHOPPING AT MyStyle: A SUPERIOR EXPERIENCE</h5>
        <p>
          MyStyle is one of the best sites when it comes to online shopping for
          men. The finest of material, superior design and unbeatable style go
          into the making of our men&apos;s shopping collection. Our range of
          online shopping men&apos;s wear, accessories, footwear and personal
          care products are second to none. Compared with other men&apos;s
          shopping sites, MyStyle brings you the best price products which
          won&apos;t hurt your pocket. With seasonal discounts on trendy casual
          wear, suits, blazers, sneakers and more, online shopping for men at
          MyStyle just gets even more irresistible!
        </p>
      </div>

      <div className={classes['footer-section']}>
        <h5>ONLINE SHOPPING FOR MEN: OPTIONS UNLIMITED</h5>
        <p>
          At MyStyle, our online shopping fashion for men collection features
          plenty of options to create multiple outfits. At our men&apos;s online
          shop we have brought together an exhaustive range of products from the
          best men&apos;s brands. Here is a list of must-haves from the wide
          variety of awesome products at MyStyle:
        </p>
        <ol>
          <li>
            <p>
              Opt for a charming yet laid-back look with cool T-shirts and
              casual shirts worn with stylish jeans, casual trousers or shorts.
              Stay sharp and sophisticated with our smart options in formal
              shirts and trousers. Look dapper when meeting your clients in our
              smooth suits. Put on trendy blazers for formal occasions. On your
              online men&apos;s clothes&apos; shopping journey, make sure you
              include kurtas, jackets and sherwanis from our festive wear
              collection. Stay warm and comfortable in sweaters and sweatshirts.
              Get fit and ready for adventure, with our sports and active wear
              collection.
            </p>
          </li>
          <li>
            <p>
              Once you are done with your online men&apos;s clothes&apos;
              shopping, make sure you pick up the right accessories to
              complement your look. Whether you are traveling to work or outside
              the city our wide variety of bags, backpacks and luggage
              collection will ensure you are well-packed. Our beautiful watches
              and smart watches work well to enhance your overall style
              quotient. Reach out for our sunglasses during the summers - let
              your eyes stay protected while you opt for maximum swag.
            </p>
          </li>
          <li>
            <p>
              Bring impeccable style to your shoe closet with our incredible
              collection of footwear for men. Look classy during formal and
              semi-formal occasions with derbies, loafers and oxfords. Stay hip
              and happening in parties with boat shoes, monks and brogues from
              our casual men&apos;s footwear range. Lead an active lifestyle
              with sneakers and running shoes from our sports footwear
              selection. Pick up sandals, floaters and flip-flops for a trip to
              the beach. We also host socks in our men&apos;s online shopping
              collection. That&apos;s basically everything under one roof!
            </p>
          </li>
        </ol>
        <p>
          Make sure you check out fun printed men&apos;s T-shirts featuring your
          favorite characters from DC Comics and Marvel studios. Relive the
          magic of your favorite superhero from Justice League. Fly high with
          Superman, battle the bad guys with Batman, or get trendy in
          lightning-speed with a Flash T-shirt. Grab our cool Marvel Avengers
          T-shirts. Stay powered up with the Iron Man, or walk with the warriors
          in a Thor T-shirt.
          <br />
          <br />
          Our online shopping fashion for mens collection includes even more
          amazing merchandise such as innerwear, sleepwear, track pants,
          personal care, wallets, belts and other fashion accessories.
        </p>
      </div>

      <div className={classes['footer-section']}>
        <h5> MEN&apos;S SHOPPING MADE EASY AT MYSTYLE</h5>
        <p>
          MyStyle is the most convenient men&apos;s online store, what with our
          simplified shopping and payment procedures. With just a few clicks of
          the mouse or taps on your smartphone, you can buy your favorites from
          the best men&apos;s brands right away.
        </p>
      </div>
    </>
  );
};

export default FooterMen;
